<template>
  <a
    class="home-card"
    :class="{
      'home-card--locked': card.locked,
      'home-card--upsell': salesConfig,
      'home-card--chrome': isChrome,
      'home-card--wide': isWide,
      'home-card--sale-active': courseOnSale
    }"
    @click="cardAction"
    ontouchstart
  >
    <div class="home-card__thumbnail" ref="cover">
      <div class="home-card__cover home-card__cover--square" :style="{'background-image': `url(${coverSquare})`}"></div>
      <div class="home-card__cover home-card__cover--wide" :style="{'background-image': `url(${coverWide})`}"></div>
      <div class="home-card__locked-overlay" v-if="card.locked">
        <div class="home-card__locked-badge" v-if="!salesConfig">
          <font-awesome-icon :icon="['fas', 'lock']"/>
          {{ $t('locked') }}
        </div>
      </div>
    </div>

    <div class="home-card__info">
      <div class="home-card__blur-issue-hack"></div>

      <div class="home-card__info-nest">
          <div class="home-card__discount">
            <p class="home-card__description hide-price">{{ getSaleDescription }}</p>
            <h3 class="home-card__title">{{ preventWidows(card.title) }}</h3>
          </div>
          <div class="home-card__discount-icon" v-if="salesConfig">-{{ salesConfig.course_card.percentage }}%</div>
      </div>

      <app-progress :progress="card.percent_completed || 0"></app-progress>
      <span v-if="courseOnSale && card.locked" class="home-card__top-border"></span>
      <template v-if="!courseCompleted">
        <div class="home-card__buttons" v-if="!salesConfig && !card.locked">
          <div style="display: block; height: 20px"></div>
          <app-button class="home-card__button" @click.stop="startWatching">
            <font-awesome-icon :icon="['fas', 'play']"  class="home-card__button-icon" />
            {{ buttonText }}
          </app-button>
        </div>
        <div class="home-card__buttons" v-if="!salesConfig && card.locked" @click.stop="openLockedLink()">
          <app-button class="locked">
            {{ $t('findOutMore') }}
            <font-awesome-icon :icon="['fas', 'angles-right']"  class="home-card__button-icon home-card__button-icon--sales-page" />
          </app-button>
        </div>

        <div class="home-card__buttons home-card__buttons--discount" v-if="salesConfig">
          <app-button class="home-card__button" :class="{'home-card__button_sale-active' : courseOnSale}">
            {{ isMobile ? $t('getDiscount') : salesConfig.course_card.button_text }}
            <font-awesome-icon :icon="['fas', 'angles-right']"  class="home-card__button-icon home-card__button-icon--sales-page" v-if="!isMobile" />
          </app-button>
          <app-timer class="home-card__timer home-timer" :class="{'home-timer__sale-active' : salesConfig}" :end="salesConfig.course_card.timer" @ended="dismissBanner"></app-timer>
        </div>
      </template>

    </div>

    <div class="home-card__badges">
      <div class="home-card__badge home-card__badge--inactive" v-if="!card.is_active">{{ $t('home.pending') }}</div>
      <div class="home-card__badge home-card__badge--new" v-if="card.is_new">{{ $t('newPill') }}</div>
    </div>
  </a>
</template>

<script>
// CONSTANTS
const squareSize = 454
const wideSize = 940

import { mapState, mapGetters } from 'vuex'
/* RESIZE OBSERVER POLYFILL */
import ResizeObserver from 'resize-observer-polyfill'


export default {
  name: 'HomeCard',
  props: {
    card: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data () {
    return {
      loaded: false,
      isWide: false,
      urlReady: false,
      openIosLockedLink: false
    }
  },
  computed: {
    ...mapGetters('courses', ['courseOnSale']),
    ...mapState('user', {
      firstLogin: state => state.firstLogin
    }),
    ...mapState('timer', {
      isTimer: state => state.dates
    }),
    ...mapState('pwa', {
      isIosUrlParams: state => state.isIosUrlParams
    }),
    courseCompleted () {
      return this.card.percent_completed === 1
    },
    isContinue () {
      return this.card.percent_completed && this.card.percent_completed !== 1
    },
    buttonText () {
      return this.isContinue ? this.$t('continueWatching') : this.$t('home.watchFirstVideo')
    },
    trackerEventName () {
      return this.card.locked ? 'courses_view_preview' : this.isContinue ? 'courses_view_continue_watching' : 'courses_view_start_watching'
    },
    cardLink () {
      return this.isMobile ? this.card.id : this.card.current_lesson.id
    },
    salesConfig() {
      return this.card.sales_config || null
    },
    lockedLink () {
      return this.handleSalesPageLinkParams(this.salesConfig ? this.salesConfig.course_card.url : this.card.locked_url, this.card.ios_locked_params)
    },
    getSaleDescription() {
      if (this.salesConfig) {
        return this.salesConfig.course_card.title
      }
    },
    coverSquareSize () {
      return Math.ceil(this.pixelRatio * squareSize)
    },
    coverSquare () {
      if (!this.loaded) {
        return
      }
      return `https://images.iphonephotographyschool.com/${this.card.cover_square}/${this.coverSquareSize}/${this.card.product_slug}.jpg`
    },
    coverWideSize () {
      return Math.ceil(this.pixelRatio * wideSize)
    },
    coverWide () {
      if (!this.loaded) {
        return
      }
      return `https://images.iphonephotographyschool.com/${this.card.cover_wide}/${this.coverWideSize}/${this.card.product_slug}.jpg`
    }
  },
  watch: {
    isIosUrlParams: {
      immediate: true,
      handler (val) {
        if (!val) return
        this.urlReady = true

        if (!this.openIosLockedLink) return
        window.open(this.lockedLink, '_blank')
      }
    }
  },
  methods: {
    cardAction () {
      if (this.card.locked) {
        this.openLockedLink();
      } else {
        this.trackEvent('button_clicked', { button: 'courses_view_course_selected' })
        this.$router.push({name: this.isMobile ? 'course' : 'lesson', params: {id: this.cardLink}})
      }
    },
    openLockedLink () {
      this.trackEvent('button_clicked', { button: 'courses_view_find_out_more' })

      if (this.isAppMode) {
        if (this.urlReady) {
          window.open(this.lockedLink, '_blank')
          return
        }

        this.openIosLockedLink = true
        return
      }

      window.open(this.lockedLink, '_blank')
    },

    startWatching () {
      this.fireTrackerEvent(this.trackerEventName)

      this.$router.push({name: 'lesson', params: {id: this.card.current_lesson.id}})
    },
    detectSizeChange () {
      const resizeObserver = new ResizeObserver(() => {
        this.checkSize()
      })

      resizeObserver.observe(this.$refs.cover)
    },
    checkSize () {
      if (this.$refs.cover) {
        this.isWide = this.$refs.cover.clientWidth / this.$refs.cover.clientHeight > 2
      }
    },
    dismissBanner () {
      this.$store.dispatch('courses/endSales', this.card.id)
      this.$store.dispatch('banner/closeBanner')
      this.$store.dispatch('layout/setBannerHeight', 0)
    },
    fireTrackerEvent (name) {
      this.trackEvent('button_clicked', {
        button: name,
        course_id: this.card.id,
        course_slug: this.card.product_slug,
        course_name: this.card.title,
      })
    }
  },
  mounted () {
    this.loaded = true
    this.detectSizeChange()
  }
}
</script>

<style lang="scss" scoped>
  .home-card {
    display: block;
    position: relative;
    margin-bottom: 30px;
    cursor: pointer;

    @include phone-landscape {
      margin-bottom: 0;
    }

    &:active {
      transform: scale(0.98);
      transition: transform .1s ease-in-out;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:not(.home-card--sale-active):last-child:nth-child(odd),
    &.home-card--sale-active:first-child,
    &.home-card--sale-active:last-child:nth-child(even) {
      grid-column: 1/-1;

      @include phone-landscape {
        ::v-deep(.home-card__thumbnail) {
          padding: 0 0 41.5% 0;
        }
      }
      @include tablet-portrait {
        ::v-deep(.home-card__thumbnail) {
          padding: 0 0 48.3% 0;
        }
      }
    }
    &__top-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--organe-linear, linear-gradient(90deg, #FFB55D 0.45%, #FF8C35 100%));
    }

    &--upsell {
      .home-card__thumbnail {
        filter: grayscale(0);
      }
    }

    &--wide {
      .home-card__cover--wide {
        display: none;

        @include phone-landscape {
          display: block;
        }
      }
      .home-card__cover--square {
        display: block;

        @include phone-landscape {
          display: none;
        }
      }
    }

    @include desktop-xs {
      &:hover {
        transform: none;

        .home-card__play {
          transform: translate(-50%, -50%) scale(1.2);
        }
        .home-card__cover {
          &::before {
            transform: scale(1.04);
          }
        }
      }
    }

    // &--chrome {
    //   .home-card__info-nest {
    //     background: rgba(var(--gray-8-rgb), 0.95);
    //     backdrop-filter: none;
    //   }
    //   .home-card__blur-issue-hack {
    //     border: 1px solid rgba(var(--gray-8-rgb), 0.95);
    //     border-top: none;
    //   }
    // }

    &__blur-issue-hack {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border: 15px solid var(--black);
      border-top: none;
      border-radius: 0 0 12px 12px;

      @supports not (backdrop-filter: blur(15px)) {
        border: 1px solid rgba(var(--gray-8-rgb), 0.95);
      }
    }

    &__locked-overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(var(--black-rgb), 0.6);
    }

    &__locked-badge {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      top: 12px;
      right: 12px;
      font-size: $text-md;
      line-height: 36px;
      font-weight: $semiBold;
      color: var(--gray-7);
      padding: 0 12px;
      border-radius: 18px;
      background: rgba(var(--white-rgb), 0.7);

      @include phone-landscape {
        top: 16px;
        right: 16px;
      }

      @include tablet-portrait (portrait) {
        top: 12px;
        right: 12px;
      }

      @include tablet-landscape {
        top: 24px;
        right: 24px;
      }
    }

    &__thumbnail {
      padding: 0 0 100% 0;
      border-radius: 12px;
      background-color: var(--gray-5);
      overflow: hidden;
      position: relative;
      // border-radius + overflow: hidden + CSS transform fix
      mask-image: -webkit-radial-gradient(white, black);
      -webkit-mask-image: -webkit-radial-gradient(white, black);

      @include phone-landscape {
        padding: 0 0 83% 0;
      }
      @include tablet-portrait {
        padding: 0 0 100% 0;
      }
    }

    &__cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-size: cover;
      transition: all .5s;

      &--wide {
        display: none;
      }

      // Pseudo-class used here for background
      // hover animation to work properly
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        transition: inherit;
        transform: scale(1.01);
      }
    }

    &__info {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .progress-bar {
        bottom: 100%;
        height: 5px;
      }
    }

    &__info-nest {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      justify-content: space-between;
      height: 96px;
      padding: 0 12px;
      border-radius: 0 0 12px 12px;
      background: rgba(var(--gray-8-rgb), 0.85);
      backdrop-filter: blur(15px);

      @supports not (backdrop-filter: blur(15px)) {
        background: rgba(var(--gray-8-rgb), 0.95);
      }

      @include phone-landscape {
        height: 76px;
        padding: 0 16px;
      }
      @include tablet-portrait (portrait) {
        height: 100px;
        padding: 0 12px;
      }
      @include tablet-landscape {
        height: 100px;
        padding: 0 24px;
      }
    }

    &__discount {
      padding-right: 55px;
    }

    &__discount-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 15px;
      height: 45px;
      width: 45px;
      font-weight: $bold;
      color: var(--white);
      border-radius: 50%;
      background-color: var(--red);
      transform: translateY(-50%) rotate(15deg);

      @include phone-landscape {
        right: 5px;
        height: 45px;
        width: 45px;
        font-size: $text-md;
      }
      @include tablet-portrait (portrait) {
        right: 10px;
        height: 55px;
        width: 55px;
        font-size: $text-lg;
      }
      @include tablet-landscape {
        right: 20px;
        height: 55px;
        width: 55px;
        font-size: $text-lg;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      padding: 0 12px 24px 12px;

      @include phone-landscape {
        padding: 0 16px 32px 16px;
      }

      @include tablet-portrait (portrait) {
        padding: 0 12px 24px 12px;
      }

      @include tablet-landscape {
        padding: 0 24px 32px 24px;
      }

      &--discount {
        align-items: flex-end;
      }

      .button {
        font-size: $text-sm;
        line-height: 38px;
        color: var(--gray-1);
        padding: 0 10px;
        background: rgba(var(--gray-8-rgb), 0.85);
        backdrop-filter: blur(15px);

        @include phone-portrait {
          font-size: $text-md;
          line-height: 42px;
          padding: 0 15px;
        }
        @include tablet-portrait (portrait) {
          padding: 0 8px;
        }

        &:hover {
          background: rgba(var(--gray-6-rgb), 0.85);

          @supports not (backdrop-filter: blur(15px)) {
            background: rgba(var(--gray-6-rgb), 0.95);
          }
        }
        &_sale-active {
          background: var(--white);
          color: var(--black);
        }
        &.locked {
          color: var(--black);
          background: var(--white);
        }
      }
    }

    &__button-icon {
      margin-right: 7px;

      &--sales-page {
        margin-right: 0;
        margin-left: 1px;
        margin-bottom: -1px;
      }
    }

    &__title {
      font-size: $header-sm;
      font-weight: $semiBold;
      line-height: 1.2;
      margin: 0;

      @include phone-landscape {
        font-size: $text-lg;
      }
      @include iphone-11 (landscape) {
        font-size: $header-xs;
      }
      @include iphone-11-pro (landscape) {
        font-size: $header-xs;
      }
      @include iphone-11-pro-max (landscape) {
        font-size: $header-xs;
      }
      @include iphone-12 (landscape) {
        font-size: $header-xs;
      }
      @include iphone-12-pro-max (landscape) {
        font-size: $header-xs;
      }
      @include tablet-portrait {
        font-size: $header-sm;
      }
    }

    &__description {
      font-size: $text-md;
      color: var(--gray-1);
      margin: 0;

      @include phone-landscape {
        font-size: $text-sm;
      }
      @include tablet-portrait {
        font-size: $text-md;
      }

      s {
        margin-right: 5px;
      }
    }

    &__new-price {
      font-weight: $semiBold;
      color: var(--green-light);
    }

    &__timer {
      margin-left: auto;
    }

    &__icon {
      font-size: 30px;
    }

    &__badges {
      display: flex;
      position: absolute;
      top: 0;
      right: 27px;
    }

    &__badge {
      line-height: 37px;
      font-weight: $semiBold;
      padding: 0 12px;
      margin: 0 8px 0 0;
      border-radius: 0px 0px 5px 5px;
      box-shadow: 0px 0px 7px rgba(var(--black-rgb), 0.2);

      &:last-child {
        margin: 0;
      }

      &--new {
        background: var(--green);
      }

      &--inactive {
        background: var(--red);
      }
    }
  }
</style>
