import appClient from '@shared/api/appClient'
import AppToast from '@course-portal/components/ui/AppToast.vue'
import { isMobile } from '@shared/base/appFlags'
import { useToast } from 'vue-toastification'

export default {
  namespaced: true,
  namespace: 'notifications',
  state: {
    notifications: [],
    newNotifications: false
  },
  actions: {
    getNotifications ({ dispatch, commit }) {
      return appClient.getNotifications().then(({data}) => {
        data.forEach(n => {
          dispatch('setNotifications', n).then(() => {
            dispatch('showNotification', {
              item: n,
              isMobile: isMobile()
            })
          })
        })
      })
    },
    setNotifications ({ commit }, value) {
      return new Promise((resolve) => {
        resolve()
      }).then(() => {
        commit('SET_NOTIFICATIONS', value)
      })
    },
    showNotification ({ dispatch }, payload) {
      dispatch('notifications/showToast', {
        item: payload.item,
        isMobile: payload.isMobile
      }, { root: true })
    },
    showToast ({}, payload) {
      const isBadge = payload.item.type === 'badge_unlocked'
      const toast = useToast()
      const content = {
        component: AppToast,
        props: {
          notification: payload.item
        }
      }

      toast(content, {
        bodyClassName: isBadge ? payload.item.data.badge.name : payload.item.type,
        draggable: payload.isMobile,
        onClick (closeToast) {
          if (!payload.clickEvent) {
            return
          }
          payload.clickEvent(closeToast)
        }
      })
    },
    resetIndicator ({ commit }) {
      commit('RESET_INDICATOR')
    }
  },
  mutations: {
    SET_NOTIFICATIONS (state, value) {        
      state.notifications.push(value)
      
      const unread = state.notifications = state.notifications.filter(item => {
        return !item.is_read
      })
      state.newNotifications = unread.length ? unread.length : false
    },
    REMOVE_NOTIFICATION (state, id) {
      state.notifications = state.notifications.filter(item => {
        return item.id !== id
      })
      state.newNotifications = state.notifications.length
    },
    RESET_INDICATOR (state) {
      state.newNotifications = false
    }
  }
}