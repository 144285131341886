<template>
  <div class="profile-activity">
    <template v-if="activitiesLoaded">
      <template v-if="activities.length">
        <template v-for="activity in activities" :key="activity.id">
          <component :is="activity.type" :activity="activity"></component>
        </template>

        <transition name="appear">
          <div class="profile-activity__loader" v-if="loading">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </transition>
      </template>
      <div class="profile-activity__empty" v-else>
        <font-awesome-icon :icon="['fas', 'bell']" class="profile-activity__empty-icon" />
        <h5 class="profile-activity__empty-title">{{ $t('activity.noActivity') }}</h5>
      </div>
    </template>
    <activity-placeholder v-else></activity-placeholder>
  </div>
</template>

<script>
import ActivityPlaceholder from './ProfileActivityPlaceholder.vue'
import achievement_unlocked from './activity/achievement_unlocked.vue'
import badge_unlocked from './activity/badge_unlocked.vue'
import comment_reply from './activity/comment_reply.vue'
import new_lesson from './activity/new_lesson.vue'
import engagement_reminder from './activity/engagement_reminder.vue'
import { iosview } from "@course-portal/iosview.js";
import { mapState } from 'vuex'

export default {
  name: 'ProfileActivity',
  data() {
    return {
      iosview: iosview
    }
  },
  components: {
    ActivityPlaceholder,
    achievement_unlocked,
    badge_unlocked,
    comment_reply,
    new_lesson,
    engagement_reminder
  },
  props: {
    tabId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('profile', {
      activeTab: state => state.activeTab,
      activities: state => state.activity,
      activitiesLoaded: state => state.activityLoaded,
      currentPage: state => state.activityCurrentPage,
      lastPage: state => state.activityLastPage,
      loading: state => state.activityLoading
    }),
    nextPage () {
      return this.currentPage + 1
    },
    canLoadActivity() {
      return this.currentPage < this.lastPage
        && !this.loading
        && this.activeTab === 1
    }
  },
  methods: {
    infiniteScroll () {
      window.onscroll = () => {
        const bottomOffset = 5
        const bottomOfWindow = (window.innerHeight + window.scrollY) >= document.body.offsetHeight - bottomOffset

        if (bottomOfWindow && this.canLoadActivity) {
          this.$store.dispatch('profile/getActivity', this.nextPage)
        }
      }
    }
  },
  mounted () {
    this.infiniteScroll()
    this.$store.dispatch('profile/readAllActivities').then(() => {
      this.$store.dispatch('notifications/resetIndicator')
      if (this.isAppMode) {
        this.iosview.setNotificationsBadge(0);
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .profile-activity {
    &__loader {
      position: relative;
      text-align: center;
      margin: 20px 0 0 0;
      border-radius: 100%;
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 0 3px;
        border-radius: 50%;
        background: var(--gray-6);

        &:nth-child(1){
          animation: bounce 1s ease-in-out infinite;
        }
        &:nth-child(2){
          animation: bounce 1s ease-in-out 0.33s infinite;
        }
        &:nth-child(3){
          animation: bounce 1s ease-in-out 0.66s infinite;
        }
      }
    }

    &__empty {
      text-align: center;
      max-width: 400px;
      margin: 20px auto 0 auto;
    }

    &__empty-icon {
      font-size: 32px;
      color: var(--gray-3);
      margin: 0 0 20px 0;
    }

    &__empty-title {
      font-size: $header-xs;
      margin: 0 0 10px 0;
    }

    @keyframes bounce {
      0%, 75%, 100% {
        transform: translateY(0);
      }
      25% {
        transform: translateY(-8px);
      }
    }
  }
</style>