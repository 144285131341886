<template>
  <div class="course">
    <div class="course__bg" :style="{'background-image': `url(${cover})`}" v-if="course"></div>
    <transition name="appear">
      <div class="course__bg-ph" v-if="!course"></div>
    </transition>

    <app-container :fluid="true">
      <app-row>
        <div class="course__main">
          <course-intro></course-intro>
        </div>
        <div class="course__modules">
          <sidebar></sidebar>
        </div>
      </app-row>
    </app-container>
  </div>
</template>

<script>
import AppContainer from '@course-portal/components/layout/AppContainer.vue'
import AppRow from '@course-portal/components/layout/AppRow.vue'
import CourseIntro from './CourseIntro.vue'
import Sidebar from '@course-portal/components/sidebar/index.vue'
import { mapState } from 'vuex'

export default {
  name: 'CourseIndex',
  components: {
    AppContainer,
    AppRow,
    CourseIntro,
    Sidebar
  },
  computed: {
    ...mapState('course', {
      course: state => state.course
    }),
    coverSize () {
      return Math.ceil(this.pixelRatio * window.innerWidth)
    },
    cover () {
      return `https://images.iphonephotographyschool.com/${this.isPhone ? this.course.cover_square: this.course.cover_wide}/${this.coverSize}/${this.course.product_slug}.jpg`
    }
  },
  mounted () {
    this.$store.dispatch('course/setCourse', parseInt(this.$route.params.id))
  },
  beforeUnmount () {
    this.$store.dispatch('layout/setHeaderTitle', null)
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.name !== 'lesson') {
        vm.$store.dispatch('layout/setHeaderTitle', null)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
  .course {
    .row {
      flex-direction: column;
    }

    &__bg {
      position: fixed;
      top: 56px;
      height: 100vw;
      width: 100%;
      margin-bottom: -100vw;
      background-position: 50% 50%;
      background-size: cover;
      z-index: -1;

      @supports (position: sticky) {
        position: sticky;
      }

      @include phone-landscape (landscape) {
        height: 50vw;
        margin-bottom: -50vw;
      }
      @include tablet-landscape (landscape) {
        height: 50vh;
        margin-bottom: -50vh;
      }
      @include desktop-sm {
        height: 70vh;
        margin-bottom: -70vh;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100vw;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,0) 40%,  var(--black) 100%);

        @include phone-landscape (landscape) {
          display: none;
        }
        @include iphone-11 (landscape) {
          display: none;
        }
        @include iphone-11-pro (landscape) {
          display: none;
        }
        @include iphone-11-pro-max (landscape) {
          display: none;
        }
        @include iphone-12 (landscape) {
          display: none;
        }
        @include iphone-12-pro-max (landscape) {
          display: none;
        }
        @include tablet-landscape {
          display: block;
        }
        @include tablet-landscape (landscape) {
          background: linear-gradient(180deg, rgba(0,0,0,0) 60%,  var(--black) 100%);
        }
      }
    }

    &__bg-ph {
      position: fixed;
      top: 52px;
      height: 100vw;
      width: 100%;
      margin-bottom: -100vw;
      background-color: var(--gray-6);

      @supports (position: sticky) {
        position: sticky;
      }

      @include phone-landscape (landscape) {
        height: 50vw;
        margin-bottom: -50vw;
      }
      @include tablet-landscape (landscape) {
        height: 50vh;
        margin-bottom: -50vh;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100vw;
        width: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,0) 40%,  var(--black) 100%);

        @include tablet-landscape (landscape) {
          background: linear-gradient(180deg, rgba(0,0,0,0) 60%,  var(--black) 100%);
        }
      }
    }

    &__main {
      position: relative;
      height: 60vh;
      width: 100%;
      z-index: 2;

      @include phone-landscape (landscape) {
        height: 90vh
      }
      @include tablet-portrait (portrait) {
        height: 70vh;
      }
      @include tablet-landscape (landscape) {
        height: 50vh;
      }
    }

    &__modules {
      position: relative;
      padding: 0 10px 40px 10px;
      background: var(--black);
      @include hide-scrollbar;

      @include desktop-sm {
        background: none;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        height: 130vw;
        width: 100vw;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  var(--black) 100%);
        transform: translateX(-50%);
        box-shadow: -1px 1px 0px 0px rgb(0 0 0 / 60%);

        @include tablet-landscape (landscape) {
          height: 50vh;
          background: linear-gradient(180deg, rgba(0,0,0,0) 60%,  var(--black) 100%);
        }
        @include desktop-sm {
          display: none;
        }
      }

      @include tablet-landscape (landscape) {
        :deep(.sidebar__nest) {
          height: auto;
          max-width: 70%;
          padding: 0;
          margin: 0 auto;
          overflow: visible;

          @include desktop-sm {
            max-width: 900px;
          }
        }
      }

      :deep(.course-module-ph) {
        &:before {
          display: none;
        }
      }
    }
  }
</style>
